exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adatvedelmi-js": () => import("./../../../src/pages/adatvedelmi.js" /* webpackChunkName: "component---src-pages-adatvedelmi-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-eskuvo-js": () => import("./../../../src/pages/eskuvo.js" /* webpackChunkName: "component---src-pages-eskuvo-js" */),
  "component---src-pages-eteleink-js": () => import("./../../../src/pages/eteleink.js" /* webpackChunkName: "component---src-pages-eteleink-js" */),
  "component---src-pages-hetimenu-js": () => import("./../../../src/pages/hetimenu.js" /* webpackChunkName: "component---src-pages-hetimenu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-rolunk-js": () => import("./../../../src/pages/rolunk.js" /* webpackChunkName: "component---src-pages-rolunk-js" */),
  "component---src-pages-teremberles-js": () => import("./../../../src/pages/teremberles.js" /* webpackChunkName: "component---src-pages-teremberles-js" */),
  "component---src-pages-teremberles-regi-js": () => import("./../../../src/pages/teremberles_regi.js" /* webpackChunkName: "component---src-pages-teremberles-regi-js" */),
  "component---src-pages-tortarendeles-js": () => import("./../../../src/pages/tortarendeles.js" /* webpackChunkName: "component---src-pages-tortarendeles-js" */)
}

